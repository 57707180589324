
import { Options, Vue } from "vue-class-component";
import { bundleAPI, classAPI, progressAPI, worldAPI } from "@/utils/apiCalls";
import * as types from "@/utils/Types";
import router from "@/router";
import QuestionButton from "@/components/QuestionButton.vue";

@Options({
  data() {
    return {
      classes: [
        {
          //Default class in case server is down
          classroomId: -1,
          name: "ERROR: Could not load classes",
        },
      ],
      selectedClass: null,
      selectedBundle: null,
      levels: [],
      bundles: [],
      wikiSubjects: [],
    };
  },
  async created(){
    await this.loadClasses();
  },

  mounted() {
    this.selectedBundle = this.$route.query.bundle;
    this.selectedClass = this.$route.query.class;
  },



  computed: {
    /**
     * Get the bundles belonging to the currently selected class
     * @returns Selected bundles of class
     */
    selectedClassBundles() {
      return this.bundles[this.getClassIndex(this.selectedClass)];
    },
  },
  methods: {
    /**
     * Get index of class
     * @param name Name of class
     * @returns Class index
     */
    getClassIndex(name: string): number | undefined {
      for (let i = 0; i < this.classes.length; i++) {
        if (this.classes[i].name == name) {
          return i;
        }
      }
    },
    /**
     * Get index of bundle
     * @param name Name of bundle
     * @returns Bundle index
     */
    getBundleIndex(name: string): number | undefined {
      for (let i = 0; i < this.selectedClassBundles.length; i++) {
        if (this.selectedClassBundles[i].name == name) {
          return i;
        }
      }
    },
    /**
     * Set selected class and bundle
     * @param className Class to be selected
     * @param bundleName Bundle to be selected
     */
    async setSelected(className: string, bundleName: string) {

      this.selectedClass = className;
      this.selectedBundle = bundleName;
      await this.updateLevels();
      router.push({ query: { class: className, bundle: bundleName } });
    },

    async loadClasses() {
        const response = await classAPI.getMyClassrooms();
        if (response !== null) this.classes = response;
        if (this.classes.length == 1) this.selectedClass = this.classes[0].name;
        await this.updateBundles();
        this.updateQuestionButton();
    },

    //Get the levels for the selected bundle when the bundle updates
    async updateLevels() {
      this.levels = [];
      bundleAPI
        .getAllLevelsInBundle(
          this.selectedClassBundles[this.getBundleIndex(this.selectedBundle)]
            .bundleId


        )
        .then((value) => {
          if (value !== null) this.levels = value;
        });
    },
    //Get the bundles for the selected class when the class updates
    async updateBundles() {
      this.bundles = [];
      await Promise.all(this.classes.map(async (classroom: types.classroom, index: number) => {
        const value = await classAPI.getBundles(classroom.classroomId);
        if (value !== null) {
          this.bundles[index] = value;
        } else {
          this.bundles[index] = [{ bundleId: "No bundles found", name: "No bundles found", lock: true }];
        }
      }));
      if (this.selectedBundle && this.selectedClass) {
        this.updateLevels();
      }
    },
    /**
     * Go to selected level
     * @param levelId Id of the selected level
     */
    async goToGame(levelId: number) {
      this.$store.commit("turnPreviewOff");
      this.$store.commit(
        "setBundle",
        this.selectedClassBundles[this.getBundleIndex(this.selectedBundle)]
          .bundleId
      );
      await this.loadWorld(this.$store.getters.getBundle, levelId).then(() => {
        router.push("/game");
      });
    },
    /**
     * Load world of selected level
     * @param bundleId Id of selected level bundle
     * @param levelId Id of selected level
     */
    async loadWorld(bundleId: number, levelId: number) {
      await bundleAPI.getLevel(bundleId, levelId);
      await bundleAPI.getLevelSettings(bundleId, levelId);
      await progressAPI.loadLevelSave(bundleId, levelId);
      await worldAPI.getWorldData(this.$store.getters.getLevelInfo.world);
    },

    updateQuestionButton() {
      this.wikiSubjects.push(
        {
          wikiButton: { name: this.$t("wiki.levelsel"), item: "levelselPage" },
        },
        {
          wikiButton: { name: this.$t("wiki.levclose"), item: "levclosePage" },
        },
        { wikiButton: { name: this.$t("wiki.medals"), item: "medalsPage" } }
      );
    },
  },
  components: {
    QuestionButton,
  },
})
export default class Levels extends Vue {}
